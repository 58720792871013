import React, { useEffect, useState } from "react";
import { Badge, Paper, Avatar, Chip, Typography } from "@mui/material";
import classes from "./myteam.module.css";
import pdf from "../../../assets/images/Group 772547013.png";
import {
  checkOnline,
  formatBytes,
  generateColor,
  stringAvatar,
} from "../../../utilities/commonFuntions";
import { RiDeleteBin6Line } from "react-icons/ri";
import axios from "axios";
import { ImageUrl, TEAM_MEMBER_DETAILS } from "../../../api-calls/api-urls";
import {
  authApi,
  getAuthorizationHeader,
} from "../../../api-calls/user-requests";
import { useDispatch, useSelector } from "react-redux";
import { TeamMemberData } from "../../../Redux/actions/team-actions";
import { getLanguagedataFunction } from "../../../utilities/language";
import { CallIcon, DeleteBinIcons } from "../../../components/Icons";
import { useNavigate } from "react-router-dom";
const TeamMemberDetails = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isOnline, setIsOnline] = useState(false);
  const { teamMemberData } = useSelector((state) => state.reducers.teams);
  const ln = useSelector((state) => state.reducers.allUsers.language_json);
  const { teamMembersList } = useSelector((state) => state.reducers.allUsers);

  React.useEffect(() => {
    getLanguagedataFunction(dispatch);
  }, [!ln]);

  const getTeamMemberData = async () => {
    const path = window.location.pathname.split("/");
    const { data } = await authApi.post(
      `${TEAM_MEMBER_DETAILS}/${path[path.length - 1]}`,
      {},
      {
        headers: { Authorization: getAuthorizationHeader() },
      }
    );
    dispatch(TeamMemberData(data));
  };

  useEffect(() => {
    console.log(teamMemberData);
  }, [teamMemberData]);

  useEffect(() => {
    getTeamMemberData();
  }, [!teamMemberData]);

  const handlePdfClick = async (item) => {
    console.log(item);
    const url = `${process.env.REACT_APP_IMAGE_URL}/${item.path}${item.filename}`;
    axios(url, {
      method: "GET",
      responseType: "blob",
    })
      .then((response) => {
        //Create a Blob from the PDF Stream
        const file = new Blob([response.data], {
          type: "application/pdf",
        });
        //Build a URL from the file
        const fileURL = URL.createObjectURL(file);
        //Open the URL on new Window
        window.open(fileURL, "_blank");
      })
      .catch((error) => {
        console.log(error);
      });
    // console.log(data);
    // navigate("/pdf-viewer")
    // window.redirect(url, "_black")
  };

  useEffect(() => {
    if (teamMemberData) {
      console.log(teamMemberData?.user_id?._id);
      console.log(checkOnline(teamMemberData?.user_id?._id));
    }
  }, [teamMemberData]);

  const getFileTypeImage = (name) => {
    if (name.includes("pdf")) {
      return pdf;
    }
  };

  return (
    <div>
      {ln ? (
        <div className={classes.detailspageMaindiv}>
          <Paper className={classes.TeamMemberDetailsPage1}>
            <div className={classes.locationGridCardHeader}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  columnGap: "10px",
                  width: "70%",
                }}
              >
                <Badge
                  color={
                    checkOnline(
                      teamMembersList,
                      teamMemberData?.user_id?._id
                    ) === "checkin"
                      ? "success"
                      : "error"
                  }
                  badgeContent=" "
                  variant="dot"
                  overlap="circular"
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  className={classes.LocationOnlineBadge}
                >
                  {teamMemberData &&
                  teamMemberData?.user_id &&
                  teamMemberData?.user_id?.profile_pic &&
                  teamMemberData?.user_id?.profile_pic?.filename ? (
                    <Avatar
                      alt={teamMemberData.name}
                      src={`${process.env.REACT_APP_IMAGE_URL}/${teamMemberData?.user_id?.profile_pic?.destination}${teamMemberData?.user_id?.profile_pic?.filename}`}
                      sx={{ width: 40, height: 40 }}
                    />
                  ) : (
                    <Avatar
                      {...stringAvatar(teamMemberData.name)}
                      sx={{
                        bgcolor: `${generateColor(teamMemberData.name)}`,
                      }}
                    />
                  )}
                </Badge>
                <div className={classes.memberCardText}>
                  <Typography className={classes.form_lable}>
                    {ln.team_member}
                  </Typography>
                  <Typography className={classes.CardValues}>
                    {teamMemberData.name}
                  </Typography>
                </div>
              </div>
              <div className={classes.memberCardText}>
                <Typography className={classes.form_lable}>
                  {ln.team}
                </Typography>
                <Typography className={classes.CardValues}>
                  {teamMemberData.team.team_name}
                </Typography>
              </div>
              <div className={classes.memberCardText}>
                <Typography className={classes.form_lable}>
                  {ln.mobile_number}
                </Typography>
                <Typography
                  className={`${classes.CardValues} ${classes.phoneIcom}`}
                >
                  {`+91 ${teamMemberData.phone}`}{" "}
                  <span className={classes.form_lable}>|</span> <CallIcon />
                </Typography>
              </div>
              <div className={classes.memberCardText}>
                <Typography className={classes.form_lable}>
                  {ln.department}
                </Typography>
                <Typography className={classes.CardValues}>
                  {teamMemberData.department.department_name}
                </Typography>
              </div>
            </div>
          </Paper>
          <Paper
            className={`${classes.TeamMemberDetailsPage1} ${classes.memberCardText}`}
          >
            <Typography>{ln.documents}</Typography>
            {teamMemberData && teamMemberData.documents.length > 0 ? (
              <div className={classes.documentContainer}>
                {teamMemberData.documents.map((item, index) => {
                  return (
                    <Paper
                      key={index}
                      className={classes.documentCard}
                      onClick={() => handlePdfClick(item)}
                    >
                      <img
                        src={getFileTypeImage(item.mimetype)}
                        className={classes.extensionImage}
                        width={50}
                        height={50}
                        alt="extension"
                      />
                      <Typography
                        className={`${classes.detailsname} ${classes.documentName}`}
                      >
                        {item.originalName}
                      </Typography>
                      <Typography className={classes.form_lable}>
                        {formatBytes(item.size)}
                      </Typography>
                      <Typography className={classes.detailsname}>
                        <span>
                          <DeleteBinIcons />
                        </span>
                      </Typography>
                    </Paper>
                  );
                })}
              </div>
            ) : "no team members found"}
          </Paper>
        </div>
      ) : null}
    </div>
  );
};

export default TeamMemberDetails;

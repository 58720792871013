import React, { useEffect, useState, useRef } from "react";
import {
  Paper,
  TextField,
  InputAdornment,
  Avatar,
  Badge,
  Typography,
  Button,
  Menu,
  MenuItem,
  Zoom,
  Divider,
  Chip,
} from "@mui/material";
import classes from "./chat.module.css";
import moment from "moment";
import "../../../assets/css/chat.css";
import {
  AttachmentIcon,
  BrudCrumbNextIcon,
  CamIcon,
  CloseEyeIcon,
  EmojiIcon,
  MessageSentIcon,
  OpenEyeIcon,
  SearchIcon,
} from "../../../components/Icons";
import { BsThreeDotsVertical } from "react-icons/bs";
import EmojiPicker from "emoji-picker-react";
// import 'emoji-picker-react/dist/universal/style.scss';
import image from "../../../assets/images/avatar-6.jpg";
import { sendMessage, join_groups } from "../../../utilities/Xmpp";
import {
  authApi,
  getAuthorizationHeader,
} from "../../../api-calls/user-requests";
import { GET_ONE_GROUP, GET_USER_GROUPS } from "../../../api-calls/api-urls";
import { useDispatch, useSelector } from "react-redux";
import {
  saveLastMessageList,
  selectedChatMember,
} from "../../../Redux/actions/chatActions";
import ChatContactCard from "./chatContactCard";
import {
  generateColor,
  stringAvatar,
  getTime,
  getUserGroupList,
  getConversationMessages,
  checkOnline,
} from "../../../utilities/commonFuntions";
import MessageComponent from "./messageComponent";

const Chatindex = () => {
  const dispatch = useDispatch();
  const inputRef = useRef(null);
  const flatListRef = useRef(null);
  const [serachValue, setSearchValue] = React.useState("");
  const [company_id, setCompany_id] = useState("");
  const [serachValue1, setSearchValue1] = React.useState("");
  const [showPicker, setShowPicker] = useState(false);
  const [message, setMessage] = useState("");
  const [selectedUserProfile, setSelectedUserProfile] = useState();
  const [selectedChat, setSelectedChat] = useState();
  const userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
  const [openProfile, setOpenProfile] = useState(false);
  const [openSearch, setOpenSearch] = useState(false);
  const [selectedGroupData, setSelectedGroupData] = useState();
  const [anchorEl, setAnchorEl] = useState(null);
  const { grouplist, contactList, messagesList, lastmessageIds } = useSelector(
    (state) => state.reducers.chats
  );

  const handleClose = () => {
    setAnchorEl(null);
  };
  useEffect(() => {
    console.log("From useEfect");
    dispatch(getUserGroupList());
  }, []);

  const handleFileChange = async (event) => {
    const fileObj = event.target.files && event.target.files[0];
    if (!fileObj) {
      return;
    }
    handleSendMessage(fileObj);
  };

  const openFileSelector = () => {
    inputRef.current.click();
  };

  useEffect(() => {
    if (flatListRef.current) {
      flatListRef.current.scrollTop = flatListRef.current.scrollHeight;
    }
  }, [messagesList]);

  useEffect(() => {
    console.log("beforeCondition");
    if (grouplist && grouplist.length > 0) {
      console.log("afterCondition");
      const array = grouplist.map((item) => {
        if (item.team_id) {
          return `${item.team_id}@conference.livehostings.com`;
        }
      });
      console.log(array);
      join_groups(userInfo, array, dispatch);
    }
  }, [grouplist]);

  useEffect(() => {
    if (grouplist && selectedChat) {
      const userInfo = sessionStorage.getItem("userInfo");
      if (userInfo) {
        const companyData = JSON.parse(userInfo); // Parse the string into an object
        if (companyData._id) {
          setCompany_id(companyData._id);
          dispatch(getConversationMessages(selectedChat, companyData._id));
        }
      }
    }
  }, [grouplist, selectedChat]);

  // const getGroupDetails = async () => {
  //   if (selectedChat && selectedChat.team_id) {
  //     const { data } = await authApi.get(`${GET_ONE_GROUP}/${selectedChat._id}`, {
  //       headers: { Authorization: getAuthorizationHeader() },
  //     });
  //     setSelectedGroupData(data);
  //   }
  // }

  // useEffect(() => {
  //   getGroupDetails(selectedChat);
  // }, [openProfile === true]);

  const handleSendMessage = async (value) => {
    console.log(value);

    await sendMessage(
      messagesList,
      userInfo,
      selectedChat,
      message,
      value,
      dispatch,
      userInfo
    );
    dispatch(getConversationMessages(selectedChat, company_id));
    setMessage("");
  };

  // const getSelectedProfilePic = async (jid, dispatch) => {
  //   const id = jid?.split("@")[0];
  //   const userData = await authApi.get(`/users//user-details/${id}`, {
  //     headers: { Authorization: getAuthorizationHeader() },
  //   });
  //   setSelectedUserProfile(userData.data);
  // };

  // const setMessageLastMeesage = async (id, type) => {
  //   let userEntry = {
  //     conversation_type: type,
  //     conversation_id: id,
  //   };
  //   if (messagesList && messagesList.length > 0) {
  //     userEntry.message_id = messagesList[messagesList.length - 1].id;
  //     const { data } = await authApi.post(`/messages`, userEntry, {
  //       headers: { Authorization: getAuthorizationHeader() },
  //     });
  //     console.log(data);
  //   }
  // }

  const handleSetProfile = () => {
    setOpenProfile(!openProfile);
    handleClose();
  };

  const getGroupDetails = async (id) => {
    try {
      const { data } = await authApi.get(`${GET_ONE_GROUP}/${id}`, {
        headers: { Authorization: getAuthorizationHeader() },
      });
      console.log(data);
      setSelectedGroupData(data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (openProfile && selectedChat) {
      getGroupDetails(selectedChat);
    }
  }, [openProfile]);

  const OpenChat = async (member) => {
    console.log(member);
    setSelectedChat(member);
    dispatch(getConversationMessages(member, company_id));
    setMarkSeen(member);
  };

  const setMarkSeen = async (member) => {
    try {
      const { data } = await authApi.put(`/messages/mark-seen/${member._id}`, {
        headers: { Authorization: getAuthorizationHeader() },
      });
      console.log(data);
      dispatch(getUserGroupList());
    } catch (error) {
      console.log(error);
    }
  };

  const handleOpenSearch = () => {
    setOpenSearch(!openSearch);
    handleClose();
  };

  return (
    <div>
      <Paper
        className={classes.ChatContainerMainDiv}
        style={{
          gridTemplateColumns: openProfile ? `30% 43% 27%` : `33% 67%`,
          margin: "5px 0",
          // height: window.innerHeight - 120
          height: "calc(100vh - 120px)",
        }}
      >
        <div className={classes.contactListcontainer}>
          <div>
            <TextField
              sx={{
                "& legend": { display: "none" },
                "& fieldset": { top: 0 },
              }}
              placeholder="search"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              fullWidth
              className={classes.teamMemberSearch}
              id="firstNameinput"
              value={serachValue}
              onChange={(e) => setSearchValue(e.target.value)}
              size="small"
            />
            <div className={`mt-4 ${classes.ConatctList}`}>
              {grouplist.map((member, index) => (
                <ChatContactCard
                  index={index}
                  OpenChat={OpenChat}
                  member={member}
                  ChatMembers={grouplist}
                  key={index}
                />
              ))}
            </div>
          </div>
        </div>
        <div
          className={classes.Chat_messageMainCointainer}
          style={{ height: "calc(100vh - 120px)" }}
        >
          <div className={classes.MessageBoxHEader}>
            <div
              className={classes.chat_IndividualContactsMainDiv}
              style={{ alignItems: "center" }}
            >
              {selectedChat ? (
                <div className={classes.chat_IndividualContactsSubDiv}>
                  <Badge
                    color={
                      checkOnline(selectedChat?.receiverDetails[0]?._id) ===
                      "checkin"
                        ? "success"
                        : "error"
                    }
                    badgeContent=" "
                    variant="dot"
                    overlap="circular"
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "right",
                    }}
                  >
                    {selectedChat?.conversation_type === "individual" &&
                    selectedChat?.receiverDetails[0]?.profile_pic ? (
                      <Avatar
                        alt="Remy Sharp"
                        src={`${process.env.REACT_APP_IMAGE_URL}/${selectedChat?.receiverDetails[0]?.profile_pic?.destination}${selectedChat?.receiverDetails[0]?.profile_pic?.filename}`}
                      />
                    ) : (
                      <Avatar
                        {...stringAvatar(
                          `${selectedChat?.receiverDetails[0].name.trim()}}`
                        )}
                        sx={{
                          bgcolor: `${generateColor(
                            `${selectedChat?.receiverDetails[0].name}`
                          )}`,
                        }}
                      />
                    )}
                    {/* <Avatar
                      src={image}
                      sx={{
                        width: "48px",
                        height: "48px",
                      }}
                    /> */}
                  </Badge>
                  <Typography className={classes.chat_ContactPersonName}>
                    {selectedChat.name.split("-")[0]}
                  </Typography>
                </div>
              ) : (
                <div className={classes.chat_IndividualContactsSubDiv}></div>
              )}
              {selectedChat && (
                <div className={`${classes.HeaderSearchBarContainer}`}>
                  <Button
                    variant="text"
                    className={classes.CardMenu}
                    onClick={(e) => setAnchorEl(e.currentTarget)}
                  >
                    <BsThreeDotsVertical />
                  </Button>
                  <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                  >
                    <MenuItem
                      key={"viewIcon"}
                      onClick={() => handleSetProfile()}
                    >
                      {openProfile ? (
                        <div>
                          <CloseEyeIcon />
                          <span>Close contact</span>
                        </div>
                      ) : (
                        <div>
                          <OpenEyeIcon />
                          <span>View contact</span>
                        </div>
                      )}
                    </MenuItem>
                    <MenuItem key={"seachIcon"} onClick={handleOpenSearch}>
                      <SearchIcon />
                      <span>Search</span>
                    </MenuItem>
                  </Menu>
                </div>
              )}
            </div>
          </div>
          <div style={{ height: "calc(100vh - 310px)" }}>
            {selectedChat &&
            messagesList.messages &&
            messagesList.messages.length > 0 ? (
              <div
                className={`mt-3 mb-1 ${classes.MessageBoxBody}`}
                ref={flatListRef}
                style={{ overflowY: "auto", height: "calc(100vh - 310px)" }}
              >
                {messagesList.messages
                  .filter((message) =>
                    new RegExp(serachValue1, "i").test(message.message)
                  )
                  .map((message, index) => {
                    return (
                      <React.Fragment key={index}>
                        {index === 0 ? (
                          <div>
                            <Divider>
                              <Typography className={classes.Chat_lastMessage}>
                                {getTime(messagesList.messages[index]?.time)}
                              </Typography>
                            </Divider>
                          </div>
                        ) : (
                          moment(messagesList.messages[index]?.time).format(
                            "DD/MM/YYYY"
                          ) !==
                            moment(
                              messagesList.messages[index - 1]?.time
                            ).format("DD/MM/YYYY") && (
                            <div>
                              <Divider>
                                <Typography
                                  className={classes.Chat_lastMessage}
                                >
                                  {getTime(messagesList.messages[index]?.time)}
                                </Typography>
                              </Divider>
                            </div>
                          )
                        )}
                        <MessageComponent
                          key={index}
                          userInfo={userInfo}
                          message={message}
                          index={index}
                          openProfile={openProfile}
                          selectedUserProfile={selectedUserProfile?.data}
                          selectedChat={selectedChat}
                        />
                      </React.Fragment>
                    );
                  })}
              </div>
            ) : null}
          </div>
          <div className={`${classes.ChatInput}`}>
            <TextField
              sx={{
                "& legend": { display: "none" },
                "& fieldset": { top: 0 },
              }}
              placeholder="message..."
              multiline
              fullWidth
              className={classes.MessageInput}
              id="firstNameinput"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            />
            <input
              style={{ display: "none" }}
              ref={inputRef}
              type="file"
              onChange={handleFileChange}
            />
            <div className={classes.chatSendBtnsContainer}>
              <span onClick={() => openFileSelector()}>
                <AttachmentIcon />
              </span>
              <span onClick={() => setShowPicker(!showPicker)}>
                {" "}
                <EmojiIcon />
              </span>
              <span onClick={() => console.log("CamIcon Clicked")}>
                <CamIcon />
              </span>
              <span onClick={() => handleSendMessage()}>
                <MessageSentIcon />
              </span>
            </div>
            {showPicker && (
              <EmojiPicker
                pickerStyle={{ width: "100%" }}
                onEmojiClick={(emojiObject) =>
                  setMessage((prevMsg) => prevMsg + emojiObject.emoji)
                }
                emojiStyle={"twitter"}
                categories={["smileys_people"]}
              />
              // <Picker pickerStyle={{ width: "100%" }} onEmojiClick={onEmojiClick} />
            )}
          </div>
        </div>
        {openProfile ? (
          <div className={classes.Chat_ContactDetailsMainCointainer}>
            <div style={{ padding: "15px" }}>
              <div className={classes.chat_IndividualContactsSubDiv}>
                <Badge
                  color="success"
                  badgeContent=" "
                  variant="dot"
                  overlap="circular"
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                >
                  {selectedChat && selectedChat.group_name ? (
                    <Avatar
                      {...stringAvatar(selectedChat.group_name)}
                      sx={{
                        bgcolor: `${generateColor(selectedChat.group_name)}`,
                        width: 60,
                        height: 60,
                        fontSize: 16,
                        fontWeight: 600,
                        textTransform: "uppercase",
                      }}
                    ></Avatar>
                  ) : selectedUserProfile &&
                    selectedUserProfile?.profile &&
                    selectedUserProfile?.profile_pic?.filename ? (
                    <Avatar
                      alt="Remy Sharp"
                      src={`${process.env.REACT_APP_IMAGE_URL}/${selectedUserProfile?.profile_pic?.destination}${selectedUserProfile?.profile_pic?.filename}`}
                    />
                  ) : (
                    <Avatar
                      {...stringAvatar(
                        `${selectedChat.name.split("-")[0].trim()}`
                      )}
                      sx={{
                        bgcolor: `${generateColor(
                          `${selectedChat.name.split("-")[0].trim()}}`
                        )}`,
                        width: 60,
                        height: 60,
                        fontSize: 16,
                        fontWeight: 600,
                      }}
                    />
                  )}
                </Badge>
                <div>
                  <Typography className={classes.chat_ContactPersonName}>
                    {selectedChat.name.split("-")[0]}
                  </Typography>
                  <Typography className={classes.Chat_lastMessage}></Typography>
                </div>
              </div>
              <hr className={classes.Chart_contactUnderLine} />
              <div className={classes.ContactSubDiv}>
                <div>
                  <Typography className={classes.Chat_lastMessage}>
                    Since Joining
                  </Typography>
                  <Typography className={classes.chatOtherImportantTexts}>
                    {moment(selectedChat?.createdAt).format("DD/MM/YYYY")}
                  </Typography>
                </div>
                <div>
                  <Typography className={classes.Chat_lastMessage}>
                    {selectedChat.team_id ? "Group ID" : "Employee ID"}
                  </Typography>
                  <Typography className={classes.chatOtherImportantTexts}>
                    {selectedChat?._id.slice(0, 6)}
                  </Typography>
                </div>
              </div>
            </div>
            <div className={`mt-2 ${classes.MedeasLinkContainerBox}`}>
              <Typography className={classes.chatOtherImportantTexts}>
                Media, Link, and Docs
              </Typography>
              <Typography className={classes.Chat_lastMessage}>
                20 <BrudCrumbNextIcon />
              </Typography>
            </div>
            <div style={{ padding: "15px" }}>
              <Typography className={`mb-3 ${classes.chat_ContactPersonName}`}>
                {selectedChat.team_id ? "Members" : "Teams"}
              </Typography>
              <div>
                {selectedChat.team_id && selectedGroupData ? (
                  <div>
                    {selectedGroupData.admins.map((item, index) => (
                      <div
                        key={index}
                        className={classes.chat_IndividualContactsMainDiv}
                      >
                        <div className={classes.NameBars}>
                          {item &&
                          item?.profile &&
                          item?.profile_pic?.filename ? (
                            <Avatar
                              alt="Remy Sharp"
                              src={`${process.env.REACT_APP_IMAGE_URL}/${item?.profile_pic?.destination}${item?.profile_pic?.filename}`}
                            />
                          ) : (
                            <Avatar
                              {...stringAvatar(`${item.name.trim()}`)}
                              sx={{
                                bgcolor: `${generateColor(
                                  `${item.name.trim()}}`
                                )}`,
                                width: 32,
                                height: 32,
                                fontSize: 14,
                                fontWeight: 600,
                                textTransform: "uppercase",
                              }}
                            />
                          )}
                          <Typography
                            className={classes.chatOtherImportantTexts}
                          >
                            {item.name}
                          </Typography>
                        </div>
                        <Typography className={classes.Chat_lastMessage}>
                          {"Admin"}
                        </Typography>
                      </div>
                    ))}
                    {selectedGroupData.group_members.map((item, index) => (
                      <div key={index}>
                        <div className={classes.NameBars}>
                          {item &&
                          item?.profile &&
                          item?.profile_pic?.filename ? (
                            <Avatar
                              alt="Remy Sharp"
                              src={`${process.env.REACT_APP_IMAGE_URL}/${item?.profile_pic?.destination}${item?.profile_pic?.filename}`}
                            />
                          ) : (
                            <Avatar
                              {...stringAvatar(`${item.name.trim()}`)}
                              sx={{
                                bgcolor: `${generateColor(
                                  `${item.name.trim()}}`
                                )}`,
                                width: 32,
                                height: 32,
                                fontSize: 14,
                                fontWeight: 600,
                                textTransform: "uppercase",
                              }}
                            />
                          )}
                          <Typography
                            className={classes.chatOtherImportantTexts}
                          >
                            {item.name}
                          </Typography>
                        </div>
                      </div>
                    ))}
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        ) : openSearch ? (
          <div className={classes.Chat_ContactDetailsMainCointainer}></div>
        ) : null}
      </Paper>
    </div>
  );
};

export default Chatindex;
